let $pagetop = $('.l-header');
$(window).on( 'scroll', function () {
  if ( $(this).scrollTop() <= 0 ) {
    $pagetop.removeClass('scroll');
  } else {
    $pagetop.addClass('scroll');
  }
});

$(function() {
  const rotate = function(menu_circle, angle) {
    menu_circle.css({
      "transform" : "rotate("+angle+"deg)"
    });
  }
  $(window).scroll(function(){
    rotate($(".l-menu_circle"), $(window).scrollTop()*0.2);
  });

  $(".a-fade").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("fade");
    }
  });

  $(".a-slide-left").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("slide-left");
    }
  });

  $(".a-slide-right").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).addClass("slide-right");
    }
  });

  $(".top-partner_boxes").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).find("div").each(function(i) {
        $(this).delay(100 * i).queue(function() {
          $(this).addClass("slide-right").dequeue();
        });
      });
    }
  });

  $('.gotop').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 100) {
      $('.gotop').slideDown("fast");
    } else {
      $('.gotop').slideUp("fast");
    }
  });
  $('.gotop a').click(function () {
    $('body,html').animate({scrollTop: 0}, 500);
    return false;
  });
});

// GOTOPとの共同に問題
// let headerHeight = $('.l-header').outerHeight();
// let urlHash = location.hash;
// if(urlHash) {
//   $('body,html').stop().scrollTop(0);
//   setTimeout(function(){
//     let target = $(urlHash);
//     let position = target.offset().top - headerHeight;
//     $('body,html').stop().animate({scrollTop:position}, 500);
//   }, 100);
// }
// $('a[href^="#"]').click(function() {
//   let href= $(this).attr("href");
//   let target = $(href);
//   let position = target.offset().top - headerHeight;
//   $('body,html').stop().animate({scrollTop:position}, 500);
// });

// TOP
if(!document.URL.match('about|sdgs|contact|recruit|news')) {

  $(window).on('load', function() {
    var useragent = window.navigator.userAgent.toLowerCase();
    if($(window).innerWidth() > 960 ){
      if (useragent.indexOf('chrome') != -1) {
        setTimeout(function() {
          $('.l-container_top').css({opacity: '0'}).animate({opacity: '1'}, 500);
        }, 2700);
        setTimeout(function() {
          $('.l-container_top').css({
            'overflow-y':'auto',
            'height':'auto'
          });
          $('.loading').remove();
        }, 3200);
      } else {
        setTimeout(function() {
          $('.l-container_top').css({opacity: '0'}).animate({opacity: '1'}, 500);
        }, 2000);
        setTimeout(function() {
          $('.l-container_top').css({
            'overflow-y':'auto',
            'height':'auto'
          });
          $('.loading').remove();
        }, 2500);
      }
    } else {
      setTimeout(function() {
        $('.l-container_top').css({opacity: '0'}).animate({opacity: '1'}, 500);
      }, 500);
      setTimeout(function() {
        $('.l-container_top').css({
          'overflow-y':'auto',
          'height':'auto'
        });
        $('.loading').remove();
      }, 1000);
    }
  });

  $(window).on('load resize', function(){
    if($(window).innerWidth() >= 769 ){
      const rellax = new Rellax('.parallax');
    }
  });

  // var timer = 0;
  // var currentWidth = window.innerWidth;
  // $(window).resize(function(){
  //   if (currentWidth == window.innerWidth) {
  //     return;
  //   }
  //   if (timer > 0) {
  //     clearTimeout(timer);
  //   }

  //   timer = setTimeout(function () {
  //     location.reload();
  //   }, 200);
  // });
}
